import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import vars from '../theme/variables';

const {headerButtonColor} = vars;


export const ArticleIcon = () => (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="article">
            <g id="Vector">
                <path
                    d="M16.7806 4.16667V15.8333H5.11393V4.16667H16.7806ZM16.7806 2.5H5.11393C4.19727 2.5 3.44727 3.25 3.44727 4.16667V15.8333C3.44727 16.75 4.19727 17.5 5.11393 17.5H16.7806C17.6973 17.5 18.4473 16.75 18.4473 15.8333V4.16667C18.4473 3.25 17.6973 2.5 16.7806 2.5Z"
                    fill="#B1B1B4"/>
                <path
                    d="M12.6139 14.1667H6.7806V12.5H12.6139V14.1667ZM15.1139 10.8333H6.7806V9.16667H15.1139V10.8333ZM15.1139 7.5H6.7806V5.83333H15.1139V7.5Z"
                    fill="#B1B1B4"/>
            </g>
        </g>
    </svg>
);
export const DropdownIcon = () => (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="unfold_more">
            <path id="Vector"
                  d="M10.9473 4.85833L13.589 7.5L14.764 6.325L10.9473 2.5L7.12231 6.325L8.30565 7.5L10.9473 4.85833ZM10.9473 15.1417L8.30565 12.5L7.13065 13.675L10.9473 17.5L14.7723 13.675L13.589 12.5L10.9473 15.1417Z"
                  fill="#D6D5D7"/>
        </g>
    </svg>
);

export const Tick = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="check">
            <path id="Vector"
                  d="M7.32911 13.2291L3.85411 9.75414L2.67078 10.9291L7.32911 15.5875L17.3291 5.58748L16.1541 4.41248L7.32911 13.2291Z"
                  fill="#D6D5D7"/>
        </g>
    </svg>
);

export const KeyboardArrowUpIcon = (props) => (
    <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20" fill="none">
        <g id="keyboard_arrow_up">
            <path id="Vector"
                  d="M6.175 13.0875L10 9.27084L13.825 13.0875L15 11.9125L10 6.91251L5 11.9125L6.175 13.0875Z"
                  fill="#D6D5D7"/>
        </g>
    </SvgIcon>
);


export const KeyboardArrowDownIcon = (props) => (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
        <g id="keyboard_arrow_down">
            <path id="Vector"
                  d="M6.175 6.91251L10 10.7292L13.825 6.91251L15 8.08751L10 13.0875L5 8.08751L6.175 6.91251Z"
                  fill="#D6D5D7"/>
        </g>
    </SvgIcon>
);

export const HomeIcon = (props) => (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
        <g id="home">
            <path id="Vector"
                  d="M9.99984 5.15832L14.1665 8.90832V15.4167H12.4998V10.4167H7.49984V15.4167H5.83317V8.90832L9.99984 5.15832ZM9.99984 2.91666L1.6665 10.4167H4.1665V17.0833H9.1665V12.0833H10.8332V17.0833H15.8332V10.4167H18.3332L9.99984 2.91666Z"
                  fill="#D6D5D7"/>
        </g>
    </SvgIcon>
);

export const AutoModeIcon = (props) => (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
        <g id="auto_mode" clipPath="url(#clip0_845_9434)">
            <g id="Vector">
                <path
                    d="M12.7031 2.35665C11.5898 1.42999 10.2098 0.823319 8.6831 0.683319V2.02332C9.83644 2.14999 10.8898 2.60999 11.7564 3.30332L12.7031 2.35665Z"
                    fill="#D6D5D7"/>
                <path
                    d="M7.34977 2.02332V0.683319C5.82311 0.816652 4.4431 1.42999 3.32977 2.35665L4.27644 3.30332C5.14311 2.60999 6.19644 2.14999 7.34977 2.02332Z"
                    fill="#D6D5D7"/>
                <path
                    d="M3.33644 4.24332L2.38977 3.29665C1.46311 4.40999 0.856439 5.78999 0.716439 7.31665H2.05644C2.18311 6.16332 2.64311 5.10999 3.33644 4.24332Z"
                    fill="#D6D5D7"/>
                <path
                    d="M13.9764 7.31665H15.3164C15.1764 5.78999 14.5698 4.40999 13.6431 3.29665L12.6964 4.24332C13.3898 5.10999 13.8498 6.16332 13.9764 7.31665Z"
                    fill="#D6D5D7"/>
                <path
                    d="M4.68311 7.98332L6.97644 9.02332L8.01644 11.3167L9.05644 9.02332L11.3498 7.98332L9.05644 6.94332L8.01644 4.64999L6.97644 6.94332L4.68311 7.98332Z"
                    fill="#D6D5D7"/>
                <path
                    d="M8.01644 13.9833C5.94311 13.9833 4.11644 12.9233 3.04311 11.3167H4.68311V9.98332H0.683105V13.9833H2.01644V12.1833C3.34311 14.0767 5.52977 15.3167 8.01644 15.3167C11.2631 15.3167 14.0164 13.2033 14.9764 10.2767L13.6698 9.97665C12.8498 12.3033 10.6298 13.9833 8.01644 13.9833Z"
                    fill="#D6D5D7"/>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_845_9434">
                <rect width="16" height="16" fill="white"/>
            </clipPath>
        </defs>
    </SvgIcon>
);

export const ZoomInIcon = (props) => (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
        <g id="zoom_in">
            <path id="Vector"
                  d="M13.1291 11.8792H12.4707L12.2374 11.6542C13.0541 10.7042 13.5457 9.47083 13.5457 8.12916C13.5457 5.13749 11.1207 2.71249 8.12907 2.71249C5.1374 2.71249 2.7124 5.13749 2.7124 8.12916C2.7124 11.1208 5.1374 13.5458 8.12907 13.5458C9.47074 13.5458 10.7041 13.0542 11.6541 12.2375L11.8791 12.4708V13.1292L16.0457 17.2875L17.2874 16.0458L13.1291 11.8792ZM8.12907 11.8792C6.05407 11.8792 4.37907 10.2042 4.37907 8.12916C4.37907 6.05416 6.05407 4.37916 8.12907 4.37916C10.2041 4.37916 11.8791 6.05416 11.8791 8.12916C11.8791 10.2042 10.2041 11.8792 8.12907 11.8792ZM8.54574 6.04583H7.7124V7.71249H6.04574V8.54583H7.7124V10.2125H8.54574V8.54583H10.2124V7.71249H8.54574V6.04583Z"
                  fill="#D6D5D7"/>
        </g>
    </SvgIcon>
);

export const ZoomOutIcon = (props) => (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
        <g id="zoom_out">
            <path id="Vector"
                  d="M13.1291 11.8792H12.4707L12.2374 11.6542C13.0541 10.7042 13.5457 9.47083 13.5457 8.12916C13.5457 5.13749 11.1207 2.71249 8.12907 2.71249C5.1374 2.71249 2.7124 5.13749 2.7124 8.12916C2.7124 11.1208 5.1374 13.5458 8.12907 13.5458C9.47074 13.5458 10.7041 13.0542 11.6541 12.2375L11.8791 12.4708V13.1292L16.0457 17.2875L17.2874 16.0458L13.1291 11.8792ZM8.12907 11.8792C6.05407 11.8792 4.37907 10.2042 4.37907 8.12916C4.37907 6.05416 6.05407 4.37916 8.12907 4.37916C10.2041 4.37916 11.8791 6.05416 11.8791 8.12916C11.8791 10.2042 10.2041 11.8792 8.12907 11.8792ZM6.04574 7.71249H10.2124V8.54583H6.04574V7.71249Z"
                  fill="#D6D5D7"/>
        </g>
    </SvgIcon>
);

export const TonalityIcon = (props) => (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
        <g id="tonality">
            <path id="Vector"
                  d="M9.99984 1.66666C5.39984 1.66666 1.6665 5.39999 1.6665 9.99999C1.6665 14.6 5.39984 18.3333 9.99984 18.3333C14.5998 18.3333 18.3332 14.6 18.3332 9.99999C18.3332 5.39999 14.5998 1.66666 9.99984 1.66666ZM9.1665 16.6083C5.88317 16.2 3.33317 13.4 3.33317 9.99999C3.33317 6.59999 5.87484 3.79999 9.1665 3.39166V16.6083ZM10.8332 3.39166C11.6915 3.49999 12.4998 3.76666 13.2248 4.16666H10.8332V3.39166ZM10.8332 5.83332H15.1998C15.4082 6.09166 15.5998 6.37499 15.7665 6.66666H10.8332V5.83332ZM10.8332 8.33332H16.4498C16.5165 8.60832 16.5748 8.88332 16.6082 9.16666H10.8332V8.33332ZM10.8332 16.6083V15.8333H13.2248C12.4998 16.2333 11.6915 16.5 10.8332 16.6083ZM15.1998 14.1667H10.8332V13.3333H15.7665C15.5998 13.625 15.4082 13.9083 15.1998 14.1667ZM16.4498 11.6667H10.8332V10.8333H16.6082C16.5748 11.1167 16.5165 11.3917 16.4498 11.6667Z"
                  fill="#D6D5D7"/>
        </g>
    </SvgIcon>
);

export const AtlasSelectedIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
            d="M7.32911 13.2291L3.85411 9.75414L2.67078 10.9291L7.32911 15.5875L17.3291 5.58748L16.1541 4.41248L7.32911 13.2291Z"
            fill="#5A48E6"/>
    </svg>
)

export const AbsoluteIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.83301 10H3.83301V7H2.83301V6H4.83301V10ZM9.16634 9H7.16634V8.33333H8.49967C8.86634 8.33333 9.16634 8.03333 9.16634 7.66667V6.66667C9.16634 6.3 8.86634 6 8.49967 6H6.16634V7H8.16634V7.66667H6.83301C6.46634 7.66667 6.16634 7.96667 6.16634 8.33333V10H9.16634V9ZM13.1663 9.33333V6.66667C13.1663 6.3 12.8663 6 12.4997 6H10.1663V7H12.1663V7.66667H10.833V8.33333H12.1663V9H10.1663V10H12.4997C12.8663 10 13.1663 9.7 13.1663 9.33333Z"
            fill="#D6D5D7"/>
    </svg>
)
export const PercentageIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.00033 2.66663C3.71366 2.66663 2.66699 3.71329 2.66699 4.99996C2.66699 6.28663 3.71366 7.33329 5.00033 7.33329C6.28699 7.33329 7.33366 6.28663 7.33366 4.99996C7.33366 3.71329 6.28699 2.66663 5.00033 2.66663ZM5.00033 5.99996C4.44699 5.99996 4.00033 5.55329 4.00033 4.99996C4.00033 4.44663 4.44699 3.99996 5.00033 3.99996C5.55366 3.99996 6.00033 4.44663 6.00033 4.99996C6.00033 5.55329 5.55366 5.99996 5.00033 5.99996ZM11.0003 8.66663C9.71366 8.66663 8.66699 9.71329 8.66699 11C8.66699 12.2866 9.71366 13.3333 11.0003 13.3333C12.287 13.3333 13.3337 12.2866 13.3337 11C13.3337 9.71329 12.287 8.66663 11.0003 8.66663ZM11.0003 12C10.447 12 10.0003 11.5533 10.0003 11C10.0003 10.4466 10.447 9.99996 11.0003 9.99996C11.5537 9.99996 12.0003 10.4466 12.0003 11C12.0003 11.5533 11.5537 12 11.0003 12ZM3.60699 13.3333L2.66699 12.3933L12.3937 2.66663L13.3337 3.60663L3.60699 13.3333Z"
            fill="#D6D5D7"/>
    </svg>
)

export const AtlasIcon = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M10.5691 0.856522C11.1863 1.04027 11.5257 1.50608 11.6864 1.93061C11.7715 2.15569 11.8138 2.38472 11.8233 2.58887C11.824 2.60303 11.8245 2.61726 11.8248 2.63152C12.0699 2.68436 12.2865 2.77656 12.4735 2.90469C12.7791 3.11421 12.9717 3.39662 13.0884 3.68416C13.2611 4.10925 13.2829 4.57943 13.2446 4.9617C13.7989 5.28994 13.971 5.87321 13.9863 6.37846C14.0016 6.88739 13.8704 7.41767 13.7253 7.81121C14.0712 8.41026 14.05 9.02199 13.8833 9.53242C13.7296 10.003 13.4494 10.403 13.1959 10.6725C13.1015 12.1577 11.81 12.8715 11.0741 13.0846C10.0613 13.982 9.0783 14.1594 8.23633 13.8759C7.42867 13.604 6.87879 12.9473 6.61158 12.4525C6.5793 12.3928 6.5625 12.3269 6.5625 12.2601V0.80724C6.5625 0.663339 6.64018 0.52896 6.7695 0.449138C7.61693 -0.0739371 8.44018 -0.076662 9.12768 0.120882C9.74395 0.297958 10.2504 0.635935 10.5691 0.856522ZM7.4922 1.05136V12.1536C7.71126 12.5176 8.09014 12.9108 8.55405 13.0669C8.99606 13.2158 9.64562 13.1917 10.5048 12.3962C10.5644 12.3411 10.639 12.3019 10.7208 12.283C11.2622 12.1577 12.2717 11.6191 12.2717 10.4981C12.2717 10.3839 12.3207 10.2745 12.4079 10.1938C12.6022 10.0139 12.8649 9.67684 12.9934 9.28332C13.1178 8.90219 13.1118 8.49142 12.8256 8.09387C12.7407 7.97594 12.7239 7.82688 12.7807 7.69529C12.9251 7.3611 13.0704 6.84909 13.0569 6.40252C13.0434 5.95441 12.8875 5.71242 12.5896 5.62047C12.3672 5.55183 12.2348 5.3406 12.2807 5.12777C12.3503 4.80599 12.3631 4.34401 12.2182 3.9871C12.1486 3.8158 12.0497 3.68524 11.9203 3.59651C11.7941 3.51001 11.6054 3.44009 11.3093 3.44009C11.1568 3.44009 11.014 3.37084 10.9271 3.25478C10.8403 3.13872 10.8205 2.99061 10.8741 2.85843C10.882 2.83898 10.9007 2.76016 10.8945 2.62603C10.8886 2.50011 10.8618 2.35498 10.8089 2.21519C10.7015 1.93125 10.5151 1.73005 10.2412 1.66438C10.1825 1.6503 10.1273 1.62573 10.0788 1.59204C9.75642 1.36817 9.34005 1.08317 8.85211 0.942975C8.44549 0.826136 7.99051 0.809963 7.4922 1.05136Z"
              fill="#A6A6A6"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M3.43184 0.855922C2.84406 1.02613 2.48996 1.41593 2.31559 1.84137C2.20947 2.1003 2.1677 2.37471 2.17271 2.63213C1.9287 2.68506 1.71285 2.77707 1.52653 2.90479C1.22085 3.11432 1.02835 3.39673 0.911573 3.68428C0.738938 4.10939 0.717098 4.57959 0.755436 4.96187C0.201084 5.29012 0.0290008 5.87342 0.0137362 6.37868C-0.00163937 6.88763 0.12957 7.41793 0.274729 7.81149C-0.0711975 8.41056 -0.0499692 9.02231 0.116704 9.53276C0.270377 10.0034 0.550586 10.4034 0.804058 10.6729C0.897098 12.136 2.15183 12.8506 2.89263 13.0753C3.27344 13.5072 3.70256 13.7806 4.15974 13.9108C4.65532 14.052 5.14241 14.0132 5.5734 13.8802C6.41166 13.6215 7.09912 12.9887 7.38842 12.453C7.4207 12.3932 7.4375 12.3273 7.4375 12.2605V0.807276C7.4375 0.66337 7.35982 0.528985 7.2305 0.44916C6.38307 -0.0739334 5.55982 -0.0766656 4.87232 0.120879C4.25663 0.297792 3.75056 0.635313 3.43184 0.855922ZM6.5078 1.05141V12.1506C6.29187 12.4844 5.82268 12.8959 5.2794 13.0636C4.99674 13.1508 4.71071 13.1671 4.43316 13.0881C4.15687 13.0094 3.84745 12.8243 3.53838 12.4428C3.47432 12.3637 3.38282 12.3074 3.27924 12.2835C2.73784 12.1582 1.72828 11.6195 1.72828 10.4985C1.72828 10.3843 1.6793 10.2748 1.59212 10.1941C1.39781 10.0142 1.13513 9.67719 1.00663 9.28365C0.882175 8.90251 0.888178 8.49172 1.17444 8.09416C1.25935 7.97623 1.27611 7.82716 1.21926 7.69556C1.07488 7.36136 0.929586 6.84933 0.943078 6.40275C0.956616 5.95463 1.11248 5.71263 1.41042 5.62067C1.63283 5.55203 1.76523 5.34079 1.71925 5.12795C1.64974 4.80615 1.63685 4.34416 1.7818 3.98724C1.85137 3.81593 1.95027 3.68536 2.07972 3.59663C2.20591 3.51013 2.39462 3.44021 2.69073 3.44021C2.83388 3.44021 2.96904 3.37915 3.05713 3.27468C3.14522 3.17021 3.17642 3.034 3.1417 2.90542C3.08225 2.68523 3.08407 2.39257 3.18481 2.14677C3.27888 1.91726 3.4515 1.73809 3.75879 1.66443C3.81749 1.65035 3.87266 1.62578 3.92118 1.59209C4.24358 1.3682 4.65995 1.08321 5.14788 0.943006C5.55451 0.826167 6.00949 0.809997 6.5078 1.05141Z"
              fill="#A6A6A6"/>
    </svg>
)


export const LockIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            d="M12.0003 5.66667H11.3337V4.33333C11.3337 2.49333 9.84032 1 8.00033 1C6.16033 1 4.66699 2.49333 4.66699 4.33333H6.00033C6.00033 3.22667 6.89366 2.33333 8.00033 2.33333C9.10699 2.33333 10.0003 3.22667 10.0003 4.33333V5.66667H4.00033C3.26699 5.66667 2.66699 6.26667 2.66699 7V13.6667C2.66699 14.4 3.26699 15 4.00033 15H12.0003C12.7337 15 13.3337 14.4 13.3337 13.6667V7C13.3337 6.26667 12.7337 5.66667 12.0003 5.66667ZM12.0003 13.6667H4.00033V7H12.0003V13.6667ZM8.00033 11.6667C8.73366 11.6667 9.33366 11.0667 9.33366 10.3333C9.33366 9.6 8.73366 9 8.00033 9C7.26699 9 6.66699 9.6 6.66699 10.3333C6.66699 11.0667 7.26699 11.6667 8.00033 11.6667Z"
            fill="#D6D5D7"/>
    </svg>
)

export const UnlockIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            d="M12.0003 5.66667H11.3337V4.33333C11.3337 2.49333 9.84032 1 8.00033 1C6.16033 1 4.66699 2.49333 4.66699 4.33333H6.00033C6.00033 3.22667 6.89366 2.33333 8.00033 2.33333C9.10699 2.33333 10.0003 3.22667 10.0003 4.33333V5.66667H4.00033C3.26699 5.66667 2.66699 6.26667 2.66699 7V13.6667C2.66699 14.4 3.26699 15 4.00033 15H12.0003C12.7337 15 13.3337 14.4 13.3337 13.6667V7C13.3337 6.26667 12.7337 5.66667 12.0003 5.66667ZM12.0003 13.6667H4.00033V7H12.0003V13.6667Z"
            fill="#D6D5D7"/>
        <path
            d="M8.00033 11.6667C8.73366 11.6667 9.33366 11.0667 9.33366 10.3333C9.33366 9.6 8.73366 9 8.00033 9C7.26699 9 6.66699 9.6 6.66699 10.3333C6.66699 11.0667 7.26699 11.6667 8.00033 11.6667Z"
            fill="white"/>
        <path d="M10,5 L10,3 C10,1.9 9.1,1 8,1 C6.9,1 6,1.9 6,3 L6,5" stroke="#D6D5D7" strokeWidth="1.5"
              strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export const InfoIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
            d="M9.16675 5.83329H10.8334V7.49996H9.16675V5.83329ZM9.16675 9.16663H10.8334V14.1666H9.16675V9.16663ZM10.0001 1.66663C5.40008 1.66663 1.66675 5.39996 1.66675 9.99996C1.66675 14.6 5.40008 18.3333 10.0001 18.3333C14.6001 18.3333 18.3334 14.6 18.3334 9.99996C18.3334 5.39996 14.6001 1.66663 10.0001 1.66663ZM10.0001 16.6666C6.32508 16.6666 3.33341 13.675 3.33341 9.99996C3.33341 6.32496 6.32508 3.33329 10.0001 3.33329C13.6751 3.33329 16.6667 6.32496 16.6667 9.99996C16.6667 13.675 13.6751 16.6666 10.0001 16.6666Z"
            fill="#D6D5D7"/>
    </svg>
)


export const SliderIncludeIcon = ({color}) => {
    const fillColor = color ? color : headerButtonColor
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.4761 7.74501L11.7561 9.46501L10.5494 8.25834L12.0027 6.79834L7.20273 1.99834L5.74273 3.45834L4.53606 2.24501L6.25606 0.52501C6.77606 0.00500977 7.62273 0.00500977 8.14273 0.52501L13.4761 5.85834C14.0027 6.37834 14.0027 7.21834 13.4761 7.74501ZM13.3294 12.925L12.3894 13.865L9.86939 11.3517L8.14939 13.0717C7.62939 13.5917 6.78273 13.5917 6.26273 13.0717L0.929394 7.73834C0.409394 7.21834 0.409394 6.37168 0.929394 5.85168L2.64939 4.13168L0.129395 1.61168L1.06939 0.671676L13.3294 12.925ZM8.66273 10.145L7.73606 9.21834L7.20273 9.75168L4.25606 6.79834L4.78939 6.26501L3.85606 5.33834L2.40273 6.79834L7.20273 11.5983L8.66273 10.145ZM10.1494 6.79834L7.20273 3.85168L6.66939 4.38501L9.61606 7.33168L10.1494 6.79834Z"
                fill={fillColor}
            />
        </svg>
    )
}
